import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { ChevronRight, ChevronLeft, ArrowLeft } from 'react-feather';

// import motor from '@static/img/motor.svg';
// import battery from '@static/img/battery.svg';
// import fork from '@static/img/fork.svg';
// import wheels from '@static/img/wheels.svg';
// import ciclista from '@static/img/ciclista.svg';
// import breaks from '@static/img/break.svg';

// import ProductSelector from '@components/ProductSelector/ProductSelector';

import * as styles from './ProductDetail.module.css';
import { Link } from 'gatsby';

const ProductDetail = ({ product }) => {
  const en = product.lang === 'en';

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <div>
        <ChevronRight />
      </div>
    ),
    prevArrow: (
      <div>
        <ChevronLeft />
      </div>
    ),
  };

  const handleJSONld = p => {
    if (typeof document !== 'undefined') {
      const images = p.images.map(i => {
        const image = require(`@static/img/${i}`).default;
        return `https://www.tizianobiellersport.com${image}`;
      });
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.textContent = JSON.stringify({
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: `Noleggio E-Bike ${p.name} a Champoluc - Valle d'Aosta`,
        description: `${p.description.substr(0, 120)}...`,
        image: images,
        brand: 'E-Bike - Tiziano Bieller Sport',
        category: 'Noleggio E-Bike',
        model: p.name,
        review: [
          {
            '@type': 'Review',
            reviewRating: {
              '@type': 'Rating',
              ratingValue: '5',
            },
            author: {
              '@type': 'Person',
              name: 'Seb Quish',
            },
          },
          {
            '@type': 'Review',
            reviewRating: {
              '@type': 'Rating',
              ratingValue: '5',
            },
            author: {
              '@type': 'Person',
              name: 'Alessandro Conca',
            },
          },
          {
            '@type': 'Review',
            reviewRating: {
              '@type': 'Rating',
              ratingValue: '5',
            },
            author: {
              '@type': 'Person',
              name: 'Andrea Bottin',
            },
          },
        ],
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.6',
          bestRating: '5',
          ratingCount: '27',
          reviewCount: '27',
          worstRating: '1',
        },
        offers: {
          '@type': 'Offer',
          url: `https://www.tizianobiellersport.com${p.url}`,
          priceCurrency: 'EUR',
          price: p.prices.full.toFixed(2).toString(),
          availability: 'https://schema.org/InStock',
        },
      });

      document.head.appendChild(script);
    }
  };

  const renderImages = () => {
    return product.images.map((i, index) => {
      const image = require(`@static/img/${i}`).default;
      return (
        <img
          key={index}
          className={styles.image}
          src={image}
          alt={product.name}
        />
      );
    });
  };

  /* eslint-disable */
  useEffect(() => {
    handleJSONld(product);
  }, []);
  /* eslint-enable */

  return (
    <div className={styles.container}>
      <div className={styles.fullBack}>
        <Link to={!en ? '/ebike' : '/en/ebike'}>
          <ArrowLeft />
          <span className={styles.fullBackText}>
            {!en ? 'Torna alle E-Bike' : 'Back to E-Bikes'}
          </span>
        </Link>
      </div>
      <h1 className={styles.full}>{product.name}</h1>
      <div className={styles.left}>
        <Slider {...sliderSettings}>{renderImages()}</Slider>
      </div>
      <div className={styles.right}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>{!en ? 'DURATA' : 'TIME'}</th>
              <th>{!en ? 'PREZZO' : 'PRICE'}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1H</td>
              <td>15&euro;</td>
            </tr>
            <tr>
              <td>2H</td>
              <td>30&euro;</td>
            </tr>
            <tr>
              <td>
                1/2 {!en ? 'GIORNO' : 'DAY'} <small>(9/13 o 14/18)</small>
              </td>
              {product.id === '0001' || product.id === '0003' ? (
                <td>45&euro;</td>
              ) : (
                <td>40&euro;</td>
              )}
            </tr>
            <tr>
              <td>1 {!en ? 'GIORNO' : 'DAY'}</td>
              {product.id === '0001' || product.id === '0003' ? (
                <td>55&euro;</td>
              ) : (
                <td>50&euro;</td>
              )}
            </tr>
          </tbody>
        </table>

        <div className={styles.other}>
          <strong>
            {!en
              ? 'Hai bisogno di altre informazioni o vuoi prenotare la tua E-Bike?'
              : 'Do you need some other info or you want to book your E-Bike?'}
          </strong>
          <br />
          {!en ? 'Contattaci subito via email' : 'Contact us by email'}{' '}
          <a href="mailto:info@tizianobiellersport.com">
            info@tizianobiellersport.com
          </a>{' '}
          {!en ? 'o telefonicamente' : 'or by phone'}{' '}
          <a href="tel:3394329988">339.4329988</a> -{' '}
          <a href="tel:0125308111">0125.308111</a>
        </div>
      </div>

      <div
        className={styles.desc}
        dangerouslySetInnerHTML={{ __html: product.description }}
      ></div>

      {/* {(product.id === '0001' || product.id === '0003') && (
        <div className={styles.specs}>
          <div className={styles.spec}>
            <img src={motor} className={styles.specIcon} alt="Motore" />
            <h3 className={styles.specTitle}>MOTORE</h3>
            <div>Yamaha PW-ST System, 70Nm, 25km/h</div>
          </div>
          <div className={styles.spec}>
            <img src={battery} className={styles.specIcon} alt="Batteria" />
            <h3 className={styles.specTitle}>BATTERIA</h3>
            <div>InTube 630Wh</div>
          </div>
          <div className={styles.spec}>
            <img src={fork} className={styles.specIcon} alt="Forcella" />
            <h3 className={styles.specTitle}>FORCELLA</h3>
            <div>RockShox 35 Silver, Travel: 160mm</div>
          </div>
          <div className={styles.spec}>
            <img
              src={wheels}
              className={styles.specIcon}
              alt="DIMENSIONI RUOTE DIFFERENTI"
            />
            <h3 className={styles.specTitle}>DIMENSIONI RUOTE DIFFERENTI</h3>
            <div>27.5" / 29"</div>
          </div>
        </div>
      )}

      {product.id === '0002' && (
        <div className={styles.specs}>
          <div className={styles.spec}>
            <img
              src={ciclista}
              className={styles.specIconDouble}
              alt="ALTEZZA CICLISTA"
            />
            <h3 className={styles.specTitle}>ALTEZZA CICLISTA</h3>
            <div>Da 140 cm</div>
          </div>
          <div className={styles.spec}>
            <img src={battery} className={styles.specIcon} alt="Batteria" />
            <h3 className={styles.specTitle}>BATTERIA</h3>
            <div>Yamaha Lithium Ion 400Wh</div>
          </div>
          <div className={styles.spec}>
            <img src={breaks} className={styles.specIcon} alt="FRENO" />
            <h3 className={styles.specTitle}>FRENO</h3>
            <div>Tektro M282</div>
          </div>
          <div className={styles.spec}>
            <img src={motor} className={styles.specIcon} alt="MOTORE" />
            <h3 className={styles.specTitle}>MOTORE</h3>
            <div>Yamaha PW-TE System, 60 Nm, 20 km/h</div>
          </div>
        </div>
      )} */}

      <div className={styles.fullBackBottom}>
        <Link to={!en ? '/ebike' : '/en/ebike'}>
          <span className={styles.fullBackText}>
            {!en ? 'Torna a tutte le E-Bike' : 'Back to all E-Bikes'}
          </span>
        </Link>
      </div>
    </div>
  );
};

ProductDetail.propTypes = {
  product: PropTypes.object,
};

export default ProductDetail;
