// extracted by mini-css-extract-plugin
export var container = "ProductDetail-module--container--aca7a";
export var desc = "ProductDetail-module--desc--ba3ed";
export var full = "ProductDetail-module--full--dc619";
export var fullBack = "ProductDetail-module--fullBack--5536e";
export var fullBackBottom = "ProductDetail-module--fullBackBottom--e6c5e";
export var fullBackText = "ProductDetail-module--fullBackText--dffab";
export var image = "ProductDetail-module--image--839b7";
export var left = "ProductDetail-module--left--4a1f5";
export var other = "ProductDetail-module--other--fec95";
export var right = "ProductDetail-module--right--ffba5";
export var spec = "ProductDetail-module--spec--ea2a6";
export var specIcon = "ProductDetail-module--specIcon--ad8b2";
export var specIconDouble = "ProductDetail-module--specIconDouble--d8c67";
export var specTitle = "ProductDetail-module--specTitle--7eee3";
export var specs = "ProductDetail-module--specs--4e169";
export var table = "ProductDetail-module--table--a46b4";