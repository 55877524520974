import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/Layout/Layout';
import Seo from '@components/Seo/Seo';
import ProductDetail from '@components/ProductDetail/ProductDetail';

const EBikeTemplate = ({ data, location }) => {
  const context = data.allSitePage.edges[0].node.pageContext;
  const en = context.lang === 'en';

  return (
    <Layout location={location} map itinerari en={en}>
      <Seo
        title={context.name}
        description={context.description.replace(/<[^>]*>?/gm, '')}
        url={location.pathname}
        lang={context.lang}
      />
      <ProductDetail product={context} />
    </Layout>
  );
};

export default EBikeTemplate;

export const query = graphql`
  query ($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          pageContext
        }
      }
    }
  }
`;
